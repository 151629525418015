<template>
  <UiMenu
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <UiBtn
        icon
        small
        v-bind="attrs"
        v-on="on"
        color="gray-60"
      >
        <IconDotsH width="14"/>
      </UiBtn>
    </template>

    <v-list nav dense color="gray-10">
      <UIFilesUploader :files-formats="allowedExtensionTypeForModule"
                       :max-file-size="$config.filesystem.project.design.file.maxUploadFileSize" multiple
                       v-if="$route.query.gallery_group_id"
                       :disabled="!abilityToUpload" @onChange="createDesign($event, true)" class="d-flex">
        <v-list-item ripple class="cursor-pointer mb-1" :disabled="!abilityToUpload">
          <v-list-item-title class="text-caption-1">
            <IconAddNewItem width="14" class="mr-2 gray-60--text"/>
            Upload Fix/New Version
          </v-list-item-title>
        </v-list-item>
      </UIFilesUploader>
      <v-list-item
        :disabled="!canDelete"
        @click="$emit('deleteFile', file)"
      >
        <v-list-item-title class="text-captions-1">
          <IconDelete width="14" class="mr-2 gray-60--text"/>
          Delete File
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        :disabled="fileLoading"
        @click="downloadFile"
      >
        <v-list-item-title class="text-captions-1">
          <IconDownload width="14" class="mr-2 gray-60--text"/>
          Download File
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        @click="openVersions"
      >
        <v-list-item-title class="text-captions-1">
          <IconSearch width="14" class="mr-2 gray-60--text"/>
          View Old Versions
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        :disabled="!checkIfDisabled || !getPermissionByModule('can-archive-design')"
        @click="updateStatus(isArchived ? $config.project.status.in_progress : $config.project.status.archived)"
      >
        <v-list-item-title class="text-captions-1">
          <IconArchive width="15" class="mr-2 gray-60--text"/>
          {{ isArchived ? 'Unarchive' : 'Archive' }} file
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </UiMenu>
</template>

<script>
import {mapGetters} from "vuex";
import UiBtn from "@/components/UI/UiBtn";
import UiMenu from "@/components/UI/UiMenu";
import UIFilesUploader from "@/components/UI/UIFilesUploader.vue";
import {getFileExtension} from "@/utils/helpers";

export default {
  name: 'FilePreviewActionsMenu',
  components: {
    UIFilesUploader,
    IconDotsH: () => import('@/components/icons/IconDotsH'),
    IconDownload: () => import('@/components/icons/IconDownload'),
    IconAddNewItem: () => import('@/components/icons/IconAddNewItem'),
    IconDelete: () => import('@/components/icons/IconDelete'),
    IconSearch: () => import('@/components/icons/IconSearch'),
    IconArchive: () => import('@/components/icons/IconArchive'),

    UiMenu,
    UiBtn,
  },
  props: {
    abilityToUpload: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    file: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      fileLoading: false,
    }
  },
  computed: {
    ...mapGetters([
      'getActiveFile',
      'getActiveDesignModule',
      'showFilesCarousel',
      'getProjectPermissionData',
      'getPermission',
      'getActiveStyle',
    ]),

    allowedExtensionTypeForModule() {
      if(this.getActiveDesignModule.slug === this.$config.project.modules.cadDesign && this.file) {
        const idx = this.$config.project.modulesFilesFormatGroup['cad-design'].findIndex(types => types.includes(getFileExtension(this.file?.file?.original_name)))
        return this.$config.project.modulesFilesFormatGroup['cad-design'][idx]
      } else  {
        return this.$config.project.modulesFileFormats[this.getActiveDesignModule.slug]
      }
    },
    isArchived() {
      return this.file && this.file.status === this.$config.project.status.archived;
    },
    checkIfDisabled() {
      return this.file.status !== (this.isArchived ? this.$config.project.status.in_progress : this.$config.project.status.archived);
    },
  },
  methods: {
    async createDesign(event) {
      await this.go()
      this.$emit('newVersion', event)
    },
    async openVersions() {
      await this.go()
      await this.$store.dispatch('toggleFilesCarousel', true)
    },
    async go() {
      if (this.$route.query.gallery_group_id === this.file.gallery_group_id) return;
      await this.$router.$updateQueryParams({gallery_group_id: this.file.gallery_group_id, file_id: this.file.id});
      await this.$store.dispatch('setActiveFile', this.file);
    },
    async downloadFile() {
      if (this.fileLoading) return;
      this.fileLoading = true;

      console.log(this.file)
      const image = await fetch(this.file.file.download_url || this.file.file.url);
      const imageBlog = await image.blob();
      const imageURL = URL.createObjectURL(imageBlog);

      const anchor = document.createElement('a');
      anchor.href = imageURL;
      anchor.download = this.file.file.download_file_name || this.file.file.original_name;

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      URL.revokeObjectURL(imageURL);
      this.fileLoading = false;
    },
    getPermissionByModule(permission) {
      const project_id = this.$route.params.project_id;
      return this.getPermission(project_id).design[permission] &&
        this.getProjectPermissionData(project_id).availableModules.includes(this.getActiveDesignModule.slug);
    },
    async updateStatus(status) {
      if (!this.getPermissionByModule('can-archive-design') || this.$store.state.ProjectDesign.activeFile.loading) return;

      try {
        if (this.file.id === this.getActiveFile.id) {
          this.$store.state.ProjectDesign.activeFile.loading = true;
          const items = this.getActiveStyle.items.map(file => file.id === this.getActiveFile.id ? {
            ...file,
            loading: true
          } : file);
          await this.$store.dispatch('setActiveStyle', {...this.getActiveStyle, items});
        }

        const res = await this.$api.projectModuleDesign.updateMultipleItemsStatus(this.$route.params.project_id, this.getActiveDesignModule.id, {
          status: status,
          items: [this.file.id],
        });
        const updatedStyle = {
          ...this.getActiveStyle,
          items: this.getActiveStyle.items.map(file => {
            if(file.id === this.file.id) {
              return {
                ...file,
                status: status,
                updated_at: res.data[0].updated_at,
                loading: false,
              }
            }
            return {...file, loading: false};
          })
        }
        await this.$store.dispatch('setActiveStyle', updatedStyle);
        if(this.file.id === this.getActiveFile.id) {
          await this.$store.dispatch('setActiveFile', {
            ...this.getActiveFile,
            status: status,
            updated_at: res.data[0].updated_at,
            loading: false,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
  }
}
</script>
