<template>
  <div class="design-view-mode fill-width align-center justify-center" :class="isXlsFile || isPdfFile  || !isImage? 'd-flex': 'd-none'">
    <XlsView
      v-if="isXlsFile"
      v-show="!getActiveFile.loading"
      :url="getActiveFile.file.url"
      :name="getActiveFile.file.original_name"
      @load="fileLoaded"
    />
    <iframe
      v-else-if="isPdfFile"
      v-show="!getActiveFile.loading"
      :src="getActiveFile.file.url"
      @load="fileLoaded"
      frameborder="0"
      class="file_iframe"
    />
    <img
      v-else-if="isImage"
      draggable="false"
      @load="fileLoaded"
      v-show="!getActiveFile.loading"
      :src="getActiveFile.file.url"
      alt=""
      class="file_image"
    />
    <div class="d-flex flex-column align-center" v-else>
      <UiFileIcon width="64" :extension="getFileName(getActiveFile.file.original_name).ext" class="mb-4 ml-n2"/>
      <div class="text-body-lg font-weight-regular gray-60--text">No Preview</div>
      <div class="text-captions-1 font-weight-regular gray-60--text">(you can still leave comments here)</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import XlsView from "@/components/project/XlsView.vue";
import {getFileExtension, getFileName} from "@/utils/helpers";
import UiFileIcon from "@/components/UI/UiFileIcon.vue";

export default {
  name: "ViewMode",
  components: {UiFileIcon, XlsView },
  computed: {
    ...mapGetters(["getActiveFile", "getActiveStyle"]),
    isPdfFile() {
      return this.$config.filesystem.fileTypes.pdf.includes(getFileExtension(this.getActiveFile.file.original_name))
    },
    isXlsFile() {
      return this.$config.filesystem.fileTypes.xls.includes(getFileExtension(this.getActiveFile.file.original_name));
    },
    isImage() {
      return this.$config.filesystem.fileTypes.image.includes(getFileExtension(this.getActiveFile.file.original_name))
    }
  },
  watch: {
    'getActiveFile.id': {
      handler() {
        if(!this.isPdfFile && !this.isXlsFile && !this.isImage) {
          this.fileLoaded()
        }
      },
      immediate: true
    }
  },
  methods: {
    getFileName,
    fileLoaded() {
      this.$store.dispatch("setActiveFile", {
        ...this.getActiveFile,
        loading: false,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.file_image {
  height: 100%;
  max-height: 652px;
  max-width: 878px;
  object-fit: contain;
  user-select: none;
  touch-action: none;
  pointer-events: none;
}
.file_iframe {
  width: 100%;
  height: 100%;
}
</style>
