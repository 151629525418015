<template>
  <div class="design-view-files-carousel absolute" v-click-outside="{
                  handler: close,
                  include:  includeCloseElement
                }">
    <div class="grid d-flex flex-column mx-auto py-5 px-5">
      <div
        class="carousel-header text-body d-flex gray-60--text align-center justify-space-between pb-4 font-weight-semi-bold">
        Versions ({{ carouselFiles.length }})
        <ui-btn
          icon
          color="gray-60"
          @click="$store.dispatch('toggleFilesCarousel', !showFilesCarousel)"
        >
          <IconCancel width="16"/>
        </ui-btn>
      </div>

      <div class="d-flex relative">
        <VueSlickCarousel
          v-if="!fileLoading"
          :draggable="false"
          :infinite="false"
          :swipe="false"
          :touchMove="false"
          class="file-carousel d-flex flex-fill space-x-3"
          :variableWidth="true"
          :arrows="carouselFiles.length >= filesPerSlide"
          :slidesToScroll="filesPerSlide - 1"
          ref="carousel"
        >
          <template #prevArrow>
            <UiBtn icon class="my-auto" color="gray-60">
              <IconArrowLeft width="18"/>
            </UiBtn>
          </template>

          <div class="px-1" v-for="file in carouselFiles" :key="file.id">
            <CarouselCard :file="file" @click="go(file)" :hide-part-menu="latestVersion > file.version" :selected="file.id === getActiveFile.id"/>
          </div>

          <template #nextArrow>
            <UiBtn icon class="my-auto" color="gray-60">
              <IconArrowRight width="18"/>
            </UiBtn>
          </template>
        </VueSlickCarousel>

        <v-flex v-if="fileLoading" class="d-flex absolute inset-0 gray-0 justify-center align-center py-12">
          <v-progress-circular
            :size="36"
            color="accent"
            indeterminate
          />
        </v-flex>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {format} from 'timeago.js';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import {chunk, getFileExtension, getFileName} from "@/utils/helpers";
import UIFilesUploader from "@/components/UI/UIFilesUploader";
import UiBtn from "@/components/UI/UiBtn";
import CarouselCard from "@/views/project/design-view/CarouselCard";

export default {
  name: 'FilesCarousel',
  components: {
    IconCancel: () => import('@/components/icons/IconCancel'),
    IconArrowLeft: () => import('@/components/icons/IconArrowLeft'),
    IconArrowRight: () => import('@/components/icons/IconArrowRight'),

    CarouselCard,
    UiBtn,
    VueSlickCarousel,
  },
  props: {
    fileLoading: {
      type: Boolean,
      default: false
    },
    abilityToUpload: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'getProject',
      'showFilesCarousel',
      'getActiveStyle',
      'getActiveFile',
      'getActiveDesignModule',
    ]),
    filesPerSlide() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return 1
      } else if (this.$vuetify.breakpoint.smOnly) {
        return 2
      } else if (this.$vuetify.breakpoint.mdOnly) {
        return 3
      } else {
        return 4;
      }
    },
    carouselFiles() {
      return this.getActiveStyle?.items?.filter(file => +file.gallery_group_id === +this.$route.query.gallery_group_id) || []
    },
    latestVersion() {
      return Math.max(...this.carouselFiles.map(file => file.version))
    }
  },
  mounted() {
    const initialSlide = chunk([-1, ...this.carouselFiles.map(item => item.id)], this.filesPerSlide).findIndex(arr => arr.includes(+this.$route.query.file_id));
    this.$refs.carousel.goTo(initialSlide);
  },
  beforeDestroy() {
    this.$store.dispatch('toggleFilesCarousel', false);
  },
  methods: {
    go(file) {
      if (this.getActiveFile.id === file.id) return;
      this.$router.$updateQueryParams({file_id: file.id});
      this.$store.dispatch('setActiveFile', {...file, loading: true});
    },
    formatDate(date) {
      return format(date, 'en_US');
    },
    includeCloseElement() {
      const include = [
        ...document.querySelectorAll('.v-overlay'),
        ...document.querySelectorAll('.v-dialog'),
        ...document.querySelectorAll('.v-menu__content'),
      ]
      return include.filter(Boolean)
    },
    close() {
      this.$store.dispatch('toggleFilesCarousel', false)
    }
  },
}
</script>

<style scoped lang="scss">
.design-view-files-carousel {
  top: 100%;
  margin-top: 1px;
  left: 0;
  right: 0;
  z-index: 0;
  background: var(--v-gray-0-base);
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.25);
  max-height: 282px;

  .file-carousel {
    min-width: 0;
  }

  ::v-deep .slick-disabled svg {
    color: var(--v-gray-30-base);
  }

  .grid {
    max-width: 1442px;
    width: 100%;
  }

  .design-view-upload-file {
    position: relative;
    width: 245px !important;
    min-width: 245px;
    min-height: 195px;
    border: 1px dashed var(--v-gray-30-base) !important;

    &.active-dropzone {
      border-color: var(--v-accent-base) !important;
      background-color: var(--v-accent-10-base) !important;
    }
  }

  ::v-deep .slick-slide {
    & > div {
      display: flex;
    }
  }
}
</style>
