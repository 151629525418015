<template>
  <div class="status-note" :style="{borderColor: `var(--v-${activeFileNoteData.color}-base)`}">
    <div v-if="activeFileNoteData.firstName" class="d-flex align-center mb-2">
      <UiAvatar
        :src="activeFileNoteData.userAvatarUrl"
        :text="activeFileNoteData.firstName"
        size="26"
        class="mr-2"
      />

      <div class="pr-4 relative" style="width: 82%;">
        <div class="d-flex overflow-hidden">
          <p class="relative mb-0 text-body text-no-wrap overflow-hidden text-overflow-ellipsis font-weight-semi-bold">
            {{ activeFileNoteData.firstName }} {{ activeFileNoteData.lastName }}
          </p>
        </div>
        <div v-if="activeFileNoteData.updatedAt" class="text-captions-2">{{ timeDistance(activeFileNoteData.updatedAt) }}</div>
      </div>
    </div>
    <div class="text-body gray-100--text">{{activeFileNoteData.note}}</div>
  </div>
</template>

<script>
import UiAvatar from "@/components/UI/UiAvatar.vue";
import {formatDistanceToNow} from "date-fns";
export default {
  name: 'DesignViewPageNote',
  components: {
    UiAvatar
  },
  props: {
    activeFileNoteData: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    timeDistance(date) {
      const res = formatDistanceToNow(new Date(date), {addSuffix: true})
      return res.replace('about ', '')
    },
  }
}
</script>

<style scoped lang="scss">
.status-note {
  border: 1px solid;
  padding: 10px;
}
</style>
