<template>
  <div
    :class="['design-comment-form absolute', {'is-preview': comment.showPreview && !comment.showForm || !comment.id}]">
    <div class="comments-wrap relative pt-4 pb-3 rounded overflow-hidden d-flex flex-column">
      <div class="d-flex align-center mb-3 px-4" v-if="comment.showPreview && !comment.showForm || !comment.id">
        <UiAvatar
          :src="latestComment.created_by.avatar ? latestComment.created_by.avatar.url : ''"
          :text="latestComment.created_by.first_name"
          size="26"
          class="mr-2"
        />

        <div class="pr-4 relative" style="width: 82%;">
          <div class="d-flex overflow-hidden">
            <p
              class="relative mb-0 text-body text-no-wrap overflow-hidden text-overflow-ellipsis font-weight-semi-bold">
              {{ latestComment.created_by.first_name }} {{ latestComment.created_by.last_name || '' }}
            </p>
            <IconCircleFilled v-if="latestComment.id && !latestComment.readed_at" width="10"
                              class="flex-shrink-0 ml-2 accent--text"/>
          </div>
          <div class="text-captions-2">{{ timeDistance(latestComment.edited ? latestComment.updated_at : latestComment.created_at) }}</div>
        </div>

        <UiBtn
          v-if="comment.showForm"
          icon
          plain
          class="ml-auto mt-n2 mr-n2"
          :disabled="comment.loading"
          @click="$emit('closeItemForm')"
        >
          <IconCancel width="16" class="gray-60--text"/>
        </UiBtn>
      </div>

      <div v-if="comment.id && comment.showPreview && !comment.showForm" class="px-4">
        <p class="mb-0 text-body"
           v-html="formatToHTMLMentionMessage(latestComment.description, latestComment.tagged_users)"
           style="word-break: break-word;"/>
        <div v-if="latestComment.attachments.length" class="d-flex space-x-4 mt-2 overflow-x-auto">
          <div
            v-for="file in latestComment.attachments"
            :key="file.id"
            class="comment-attachments-item relative flex-shrink-0 rounded gray-60--text cursor-pointer"
            @click="viewFile(file)"
          >
            <UiUploadedFile class="d-flex justify-center align-center fill-width fill-height rounded overflow-hidden" :file="file"/>
          </div>
        </div>

        <UiBtn outlined class="mt-3" color="accent" width="100%" @click="$emit('showItemForm')">
          View All Thread
        </UiBtn>
      </div>

      <template v-if="!comment.id && comment.showForm">
        <ValidationObserver ref="form" v-slot="{ invalid }" tag="div" class="px-4">
          <div class="relative mb-3 ">
            <ValidationProvider rules="required" slim name="comment" vid="Comment" v-slot="{errors}">
              <UiMentionInput
                ref="comment_input"
                class="fill-width mr-2"
                :content-class="`comment-input text-captions-1 rounded overflow-y-auto ${errors[0] ? 'border-error': ''}`"
                :users="getProject.team"
                :value="formatToHTMLMentionMessage(comment.description, comment.tagged_users)"
                :disabled="!editEnable || comment.loading"
                :menu-class="'mention-comment-list-menu'"
                :placeholder="'Type description here...'"
                @editMessage="val => comment.description = val"
                @keydown.enter.exact.prevent="createComment"
              />
              <div v-if="errors[0]" class="mt-1 text-captions-1 error--text">
                {{ errors[0] }}
              </div>
            </ValidationProvider>


            <div v-if="comment.description.length > maxReplyLength" class="mt-1 text-captions-1 error--text">
              Description must have max {{ maxReplyLength }} symbols.
            </div>

            <UIFilesUploader
              v-if="editEnable"
              :files-formats="$config.filesystem.tasks.attachments.acceptedUploadFormats"
              :max-file-size="$config.filesystem.tasks.attachments.maxUploadFileSize"
              :drag-and-drop="false"
              :disabled="comment.loading"
              multiple
              @onChange="addFile"
              class="d-flex align-center absolute input-upload-btn accent--text text-captions-2 text-capitalize cursor-pointer"
            >
              <IconUpload width="14" class="mr-2"/>
              Upload files
            </UIFilesUploader>
          </div>

          <div v-if="comment.attachments.length" class="d-flex space-x-4 pt-2 mb-5 overflow-x-auto">
            <div
              v-for="file in comment.attachments"
              :key="file.id"
              class="comment-attachments-item relative flex-shrink-0 rounded gray-60--text cursor-pointer"
              @click="viewFile(file)"
            >
              <UiUploadedFile class="d-flex justify-center align-center fill-width fill-height rounded overflow-hidden" :file="file"/>

              <button
                type="button"
                :disabled="comment.loading"
                class="absolute d-flex align-center justify-center"
                @click.stop="removeFile(file.id)"
              >
                <IconCancelCircle width="18"/>
              </button>
            </div>
          </div>

          <div class="d-flex justify-space-between">
            <UiCheckbox
              v-model="createTask"
              checkbox-form="square"
              :disabled="comment.loading"
              @change="setTask"
              hide-details
              label="Create Task"
              class="my-0"
            />

            <UiBtn width="94" color="accent" :disabled="invalid" :loading="comment.loading" @click="createComment">
              Comment
            </UiBtn>
          </div>
        </ValidationObserver>
      </template>

      <div v-if="comment.id && comment.showForm" class="d-flex flex-column  overflow-hidden">
        <div class="overflow-auto px-4" ref="comment_wrap">
          <Comment
            :comment="comment"
            @deleteComment="value => $emit('deleteComment', value)"
            @editComment="editComment"
            @toggleCommentReadStatus="value => $emit('toggleCommentReadStatus', value)"
          />

          <Comment
            v-for="commentsReply in comment.children"
            :key="commentsReply.id"
            :comment="commentsReply"
            @deleteComment="value => $emit('deleteComment', value)"
            @editComment="editComment"
            @toggleCommentReadStatus="value => $emit('toggleCommentReadStatus', value)"
          />
        </div>

        <div v-if="comment.children.length < 100" class="px-4 reply-comment-input-wrap relative d-flex align-end py-2">
          <div class="d-flex align-center mr-2" style="height: 30px;">
            <UiAvatar
              :src="getUser.avatar ? getUser.avatar.url : ''"
              :text="getUser.first_name"
              size="26"
            />
          </div>

          <div class="relative fill-width mr-2" style="max-width: calc(100% - 106px)">
            <UiMentionInput
              ref="reply_input"
              class="fill-width"
              :content-class="'reply-comment-input text-captions-1 rounded overflow-y-auto'"
              :users="getProject.team"
              :disabled="!editEnable || comment.loading"
              :menu-class="'mention-comment-list-menu'"
              :placeholder="editedComment ? 'Type description here...' : 'Reply Text'"
              @editMessage="val => this.commentMessage = val"
              @blur="onBlur"
              @keydown.enter.exact.prevent="commentReply"
            />

            <UiBtn icon v-if="editEnable" @click.stop
                   width="22"
                   height="22"
                   class="d-flex align-center absolute input-upload-reply-btn accent--text text-captions-2 text-capitalize cursor-pointer">
              <UIFilesUploader
                v-if="editEnable"
                :files-formats="$config.filesystem.tasks.attachments.acceptedUploadFormats"
                :max-file-size="$config.filesystem.tasks.attachments.maxUploadFileSize"
                :drag-and-drop="false"
                :disabled="comment.loading"
                multiple
                @onChange="addFile($event, true)"
                class="d-flex justify-center align-center fill-width cursor-pointer"
                style="height: 22px;"
              >
                <IconPaperclip width="15" class="gray-60--text"/>
              </UIFilesUploader>
            </UiBtn>
          </div>


          <UiBtn width="64" color="accent" :loading="comment.replyLoading" @click="commentReply">
            Reply
          </UiBtn>
        </div>

        <div v-if="commentMessage.trim().length > maxReplyLength" class=" px-4 text-captions-1 error--text"
             style="margin-left: 34px;">
          Reply must have max {{ maxReplyLength }} symbols.
        </div>

        <div v-if="commentAttachments.length" class=" px-4 d-flex space-x-4 pt-2 flex-shrink-0 overflow-x-auto">
          <div
            v-for="file in commentAttachments"
            :key="file.id"
            class="comment-attachments-item relative flex-shrink-0 rounded gray-60--text cursor-pointer"
            @click="viewFile(file)"
          >

            <UiUploadedFile class="d-flex justify-center align-center fill-width fill-height rounded overflow-hidden" :file="file"/>


            <button
              type="button"
              :disabled="comment.replyLoading"
              class="absolute d-flex align-center justify-center"
              @click.stop="removeFile(file.id, true)"
            >
              <IconCancelCircle width="18"/>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {formatDistanceToNow} from 'date-fns';
import {formatToHTMLMentionMessage, getFileExtension, getFileName, getFileThumbnails} from "@/utils/helpers";
import UiAvatar from "@/components/UI/UiAvatar";
import UiBtn from "@/components/UI/UiBtn";
import UIFilesUploader from "@/components/UI/UIFilesUploader";
import UiCheckbox from "@/components/UI/UiCheckbox";
import UiMentionInput from "@/components/UI/UiMentionInput";
import Comment from "@/views/project/design-view/modes/Comment";
import UiUploadedFile from "@/components/UI/UiUploadedFile.vue";

export default {
  name: 'TaskForm',
  components: {
    UiUploadedFile,
    IconCircleFilled: () => import('@/components/icons/IconCircleFilled'),
    IconCancel: () => import('@/components/icons/IconCancel'),
    IconUpload: () => import('@/components/icons/IconUpload'),
    IconCancelCircle: () => import('@/components/icons/IconCancelCircle'),
    IconPaperclip: () => import('@/components/icons/IconPaperclip'),

    UiAvatar,
    UiBtn,
    UIFilesUploader,
    UiCheckbox,
    UiMentionInput,
    Comment,
  },
  props: {
    comment: {
      type: Object,
      default: () => {
      },
    }
  },
  data() {
    return {
      modal: true,
      createTask: false,
      priorities: Object.values(this.$config.tasks.priority),
      statuses: Object.values(this.$config.tasks.status),
      taskList: [],
      commentMessage: '',
      commentAttachments: [],
      maxReplyLength: 1000,
      editedComment: null,
    }
  },
  computed: {
    ...mapGetters([
      'getAuthId',
      'getUser',
      'getProject',
      'getPermission',
      'getProjectPermissionData',
      'getProjectSideDrawerFilters',
      'getProjectSideDrawerTasks',
    ]),
    editEnable() {
      const {projectOwner, projectManager} = this.$config.project.userRole;

      if (this.comment.id === null) {
        return this.getPermission(this.$route.params.project_id).design['can-create-task-design'];
      }

      return this.comment.created_by.id === this.getAuthId ||
        this.getProjectPermissionData(this.$route.params.project_id).roles.some(role => role === projectOwner || role === projectManager);
    },
    latestComment() {
      return this.comment?.children?.length ? this.comment.children[this.comment.children.length - 1] : this.comment
    }
  },
  watch: {
    'comment.children': {
      handler(newVal, oldVal) {
        if (newVal.length > oldVal.length) {
          this.$nextTick(() => {
            if (this.$refs.comment_wrap) {
              this.$refs.comment_wrap.scrollTop = this.$refs.comment_wrap.scrollHeight;
            }
          });
        }
      }
    },
    'comment.replyLoading': {
      handler(newVal) {
        if (!newVal) {
          this.commentMessage = ''
          this.commentAttachments = []
          this.editedComment = null
          this.$refs.reply_input.clearInput();
        }
      }
    },
  },
  methods: {
    getFileThumbnails,
    getFileName,
    getFileExtension,
    ...mapActions([
      'setActiveTaskInTaskTab',
      'setActiveTab'
    ]),
    formatToHTMLMentionMessage,
    timeDistance(date) {
      const res = formatDistanceToNow(new Date(date), {addSuffix: true})
      return res.replace('about ', '')
    },
    viewFile({type, url, original_name}) {
      this.$store.dispatch('openModal', {
        modalName: 'previewFileModal',
        data: {
          type, url, original_name
        }
      });
    },
    onBlur() {
      if(this.editedComment && ( !this.commentMessage?.length && !this.commentAttachments.length)) {
        this.editedComment = null
      }
    },
    removeFile(id, reply) {
      if (reply) {
        this.commentAttachments = this.commentAttachments.filter(file => file.id !== id);
      } else {
        this.comment.attachments = this.comment.attachments.filter(file => file.id !== id);
      }
    },
    editComment(comment) {
      this.repliedMessage = null
      this.editedComment = JSON.parse(JSON.stringify(comment))
      this.commentAttachments = this.editedComment.attachments
      this.$refs.reply_input.focus()

      const text = formatToHTMLMentionMessage(this.decodeMessage(comment.description), comment.tagged_users).trim()
      this.commentMessage = text
      this.$refs.reply_input.setValue(text)
      this.$nextTick(() => {
        this.$refs.reply_input.focus()
      })
    },
    decodeMessage(msg) {
      return decodeURIComponent(msg);
    },
    addFile(files, reply) {
      files.forEach((file, idx) => {
        const reader = new FileReader();

        const newFile = {
          id: new Date().getTime() + idx,
          type: file.type,
          original_name: file.name,
          url: '',
          file
        }

        reader.onload = () => {
          newFile.url = reader.result;
          if (reply) {
            this.commentAttachments.push(newFile);
          } else {
            this.comment.attachments.push(newFile);
          }
        }

        reader.readAsDataURL(file);
      });
    },
    createComment() {
      if (!this.$refs.comment_input.mentionsInstance.isActive) {
        if (!this.comment.description.trim().length || this.comment.description.trim().length > this.maxReplyLength || !this.editEnable || this.comment.loading) return;

        const {message, users} = this.$refs.comment_input.getFormattedMessageData();

        this.comment.description = message.toString();
        this.comment.tagged_users = users;

        this.$emit('createComment', this.comment);
      }
    },
    setTask() {
      const {message, users} = this.$refs.comment_input.getFormattedMessageData();

      this.comment.type = this.createTask ? 'task' : 'comment';
      this.comment.description = message;
      this.comment.tagged_users = users.map(user => ({id: user.id, first_name: user.userName}));
    },
    commentReply() {
      if (!this.$refs.reply_input.mentionsInstance.isActive) {
        if (!this.commentMessage.trim().length || this.commentMessage.trim().length > this.maxReplyLength) return;

        this.comment.replyLoading = true;
        const {message, users} = this.$refs.reply_input.getFormattedMessageData();

        this.editedComment ? this.$emit('updateComment', {
          ...this.editedComment,
          parent: {id: this.editedComment.parent_id},
          description: message,
          tagged_users: users,
          attachments: this.commentAttachments,
        }) : this.$emit('createComment', {
          parent: {id: this.comment.id},
          description: message,
          tagged_users: users,
          attachments: this.commentAttachments,
          created_by: this.getUser,
        });
      }
    },
  },
}
</script>

<style scoped lang="scss">
.design-comment-form {
  width: 386px;
  cursor: auto;

  &.top {
    top: calc(100% - 38px);
  }

  &.left {
    left: 100%;

    .comments-wrap {
      margin-left: 6px;
    }
  }

  &.bottom {
    bottom: calc(100% - 38px);
  }

  &.right {
    right: calc(100% + 6px);
  }

  &.is-preview {
    width: 334px;
  }

  .comments-wrap {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    background-color: var(--v-gray-10-base);
    max-height: 380px;
  }

  .input-upload-btn {
    right: 10px;
    top: 50px;
  }

  .input-upload-reply-btn {
    top: 4px;
    right: 4px;
  }

  .comment-attachments-item,
  ::v-deep .comment-attachments-item {
    height: 54px;
    width: 54px;
    border: 1px solid var(--v-gray-30-base);
    background-color: var(--v-gray-10-base);

    button {
      top: -8px;
      right: -10px;
    }
  }

  .v-text-field {
    background-color: var(--v-gray-0-base);
  }

  ::v-deep .comment-input,
  ::v-deep .reply-comment-input {
    padding: 7px 12px;
    max-height: 72px;
    background-color: var(--v-gray-0-base);
    border: 1px solid var(--v-gray-30-base);
    color: var(--v-gray-60-base);

    &:empty::before {
      color: var(--v-gray-60-base);
    }

    &:focus {
      border-color: var(--v-accent-base);
    }
  }

  ::v-deep .comment-input {
    height: 72px;
    padding: 8px 12px;
  }
}
</style>

<style lang="scss">
.mention-comment-list-menu {
  transform: translateY(-115%);
}

.border-error {
  border-color: var(--v-error-base) !important;
}
</style>
