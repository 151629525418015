var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"items_body",staticClass:"design-task-mode text-center cursor-pointer-custom"},[(!_vm.getActiveFile.loading && _vm.loading)?_c('v-flex',{staticClass:"file-loading absolute inset-0 d-flex justify-center align-center"},[_c('v-progress-circular',{attrs:{"size":100,"color":"accent","indeterminate":""}})],1):_vm._e(),_c('Portal',{attrs:{"to":"comment-portal-target"}},[_c('div',{staticClass:"pointer-events-auto",style:({ transform: _vm.transformStyle })},[_vm._l((_vm.items),function(item,idx){return [(item.active)?_c('VueDraggableResizable',{directives:[{name:"intersect",rawName:"v-intersect",value:({
                handler: (entries) => _vm.onIntersect(entries, item),
                options: {
                  root: _vm.root,
                  rootMargin: '0px',
                  threshold: 1.0,
                }
              }),expression:"{\n                handler: (entries) => onIntersect(entries, item),\n                options: {\n                  root: root,\n                  rootMargin: '0px',\n                  threshold: 1.0,\n                }\n              }"}],key:new Date(item.created_at).getTime(),style:({'z-index': item.showPreview ? _vm.getMaxItemIndex + 1 : item.parameters.z_index}),attrs:{"w":"auto","h":"auto","minWidth":32,"minHeight":32,"x":_vm.position(item).x,"y":_vm.position(item).y,"draggable":!item.loading && _vm.editEnable(item),"resizable":false,"parent":false,"dragHandle":".design-task-marker","class-name":`d-flex align-center justify-center absolute text-left ${item.showForm ? 'is-active' : ''} ${item.intersect ? '': 'hide'}`},on:{"dragging":function($event){return _vm.onDrag(item)},"dragstop":(x, y) => _vm.onDragStop(x, y, idx)}},[_c('v-hover',{model:{value:(item.showPreview),callback:function ($$v) {_vm.$set(item, "showPreview", $$v)},expression:"item.showPreview"}},[_c('div',[_c('UiBtn',{ref:`design_task_marker_${idx}`,refInFor:true,staticClass:"design-task-marker cursor-pointer-custom",attrs:{"fab":"","width":"auto","height":"auto","min-width":"32","min-height":"32","color":"accent","disabled":item.loading},on:{"click":function($event){return _vm.showItemForm(item)}}},[_vm._v(" "+_vm._s(item.parameters.number)+" ")]),(_vm.selectedItem && _vm.selectedItem.id === item.id && _vm.selectedItem.type === 'comment' && item.showForm || item.showPreview && !item.dragging && item.type === 'comment')?_c('CommentForm',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
                handler: () => _vm.closeOutsideItem(_vm.items[idx]),
                closeConditional: _vm.closeConditionalMethod,
                include:  () => _vm.includeCloseElement(idx)
              }),expression:"{\n                handler: () => closeOutsideItem(items[idx]),\n                closeConditional: closeConditionalMethod,\n                include:  () => includeCloseElement(idx)\n              }"}],class:_vm.getMenuPosition(item),attrs:{"comment":item},on:{"createComment":function($event){return _vm.saveComment($event, false)},"updateComment":function($event){return _vm.saveComment($event, true)},"toggleCommentReadStatus":_vm.toggleCommentReadStatus,"deleteComment":_vm.deleteComment,"showItemForm":function($event){return _vm.showItemForm(item)},"closeItemForm":_vm.closeItemForm,"click":function($event){$event.stopPropagation();}}}):_vm._e(),(_vm.selectedItem && _vm.selectedItem.id === item.id && _vm.selectedItem.type === 'task' && item.showForm || item.showPreview && !item.dragging && item.type === 'task')?_c('TaskForm',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
                handler: () => _vm.closeOutsideItem(_vm.items[idx]),
                closeConditional: _vm.closeConditionalMethod,
                include: () => _vm.includeCloseElement(idx)
              }),expression:"{\n                handler: () => closeOutsideItem(items[idx]),\n                closeConditional: closeConditionalMethod,\n                include: () => includeCloseElement(idx)\n              }"}],class:_vm.getMenuPosition(item),attrs:{"task":item},on:{"createTask":_vm.createTask,"updateTask":_vm.updateTask,"deleteTask":_vm.deleteTask,"showItemForm":function($event){return _vm.showItemForm(item)},"closeItemForm":_vm.closeItemForm,"wheel":_vm.onWheel,"click":function($event){$event.stopPropagation();}}}):_vm._e()],1)])],1):_vm._e()]})],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }