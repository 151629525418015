<template>
  <UiGridCard v-on="$listeners" v-bind="$attrs" :selectable="false" width="245" min-width="245"
              :item-status="file.status">
    <template v-slot:header>
      <div class="d-flex align-center pa-2">
        <div class="ml-auto">
          <FileActionsMenu :file="file" :hidePartMenu="hidePartMenu"/>
        </div>
      </div>
    </template>
    <template #bottom>
      <div class="d-flex justify-space-between align-center">
        <div class="text-captions-2 gray-80--text">
          <div>Uploaded:</div>
          <div class="font-weight-bold gray-80--text">{{ displayDate }}</div>
        </div>
        <div class="accent--text d-flex align-center text-body font-weight-semi-bold space-x-1">
          <IconCopy width="12" class="accent--text"/>
          <span>V {{ file.version }}</span>
        </div>
      </div>
    </template>
    <template #image>
      <v-flex v-if="file.loading" @click.stop class="d-flex justify-center align-center">
        <v-progress-circular
          :size="28"
          color="accent"
          indeterminate
        />
      </v-flex>
      <template v-else>
        <UIImage class="fill-width fill-height object-cover" v-if="!file.file" :name-path="`default-img.png`"/>
        <img v-else-if="$config.filesystem.fileTypes.image.includes(getFileExtension(file.file.original_name))"
             :src="file.file.url" alt="" class="fill-width fill-height object-cover">
        <UiFileIcon class="mx-auto" v-else width="48" :extension="getFileName(file.file.original_name).ext"/>
      </template>
    </template>
  </UiGridCard>
</template>

<script>
import UiGridCard from "@/components/UI/UiGridCard";
import UIImage from "@/components/UI/UIImage";
import FileActionsMenu from "@/views/project/design-view/FileActionsMenu";
import {format, formatDistanceToNowStrict, parseISO, differenceInHours} from "date-fns";
import UiFileIcon from "@/components/UI/UiFileIcon.vue";
import {getFileExtension, getFileName} from "@/utils/helpers";

export default {
  name: "CarouselCard",
  inheritAttrs: false,
  props: {
    file: {
      type: Object,
      default: null,
      required: true
    },
    hidePartMenu: {
      type: Boolean,
      default: false
    }
  },
  components: {
    UiFileIcon,
    IconCopy: () => import('@/components/icons/IconCopy'),
    FileActionsMenu,
    UIImage,
    UiGridCard
  },
  computed: {
    displayDate() {
      const date = parseISO(this.file.created_at)

      const result = differenceInHours(
        new Date(),
        new Date(date)
      )

      return result < 24 ? `${formatDistanceToNowStrict(date)} ago` : format(date, 'LLLL d, yyyy')
    },
  },
  methods: {
    getFileExtension,
    getFileName
  }
}
</script>
