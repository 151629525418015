<template>
  <div class="d-flex justify-center justify-sm-space-between">
    <UiBtn
      v-if="$vuetify.breakpoint.smAndUp"
      outlined
      color="accent"
      @click="downloadFile"
      :loading="fileLoading"
      :disbled="fileLoading"
    >
      <IconDownload width="14" class="mr-2"/>
      <div class="d-flex overflow-hidden" style="max-width: 228px">
        <div class="text-no-wrap text-overflow-ellipsis overflow-hidden">
          Download {{ getActiveDesignModule.title }} file
        </div>
      </div>
    </UiBtn>
    <div class="space-x-3">
      <template v-if="$vuetify.breakpoint.smAndUp">
        <UiBtn outlined color="accent" :disabled="latestVersion > getActiveFile?.version || !getActiveFile || !deleteDisable" @click="deleteItems">
          <IconDelete width="14" class="mr-2"/>
          Delete
        </UiBtn>
        <UiBtn outlined color="accent" :disabled="archiveDisabled || !getPermissionByModule('can-archive-design')"
               @click="archiveFile">
          <IconArchive width="14" class="mr-2"/>
          Archive
        </UiBtn>
        <UiBtn outlined color="accent"
               :disabled="!getPermission($route.params.project_id).design['can-share-design'] || true">
          <IconShare width="14" class="mr-2"/>
          Share
        </UiBtn>
      </template>

      <FileActionsMenu bottomActions :hide-part-menu="latestVersion > getActiveFile?.version"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import FileActionsMenu from './FileActionsMenu'
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: 'FileBottomActions',
  components: {
    IconDownload: () => import('@/components/icons/IconDownload'),
    IconDelete: () => import('@/components/icons/IconDelete'),
    IconArchive: () => import('@/components/icons/IconArchive'),
    IconShare: () => import('@/components/icons/IconShare'),
    UiBtn,
    FileActionsMenu
  },
  data() {
    return {
      fileLoading: false,
    }
  },
  computed: {
    ...mapGetters([
      'getAuthId',
      'getProject',
      'getActiveStyle',
      'getActiveFile',
      'getPermission',
      'showFilesCarousel',
      'getProjectPermissionData',
      'getActiveDesignModule',
    ]),
    archiveDisabled() {
      if (!this.getActiveFile) return true;
      return this.getActiveFile.status === this.$config.project.status.archived;
    },
    deleteDisable() {
      const {projectOwner, projectManager} = this.$config.project.userRole;
      return (this.getActiveFile.created_by === this.getAuthId ||
        this.getProjectPermissionData(this.$route.params.project_id).roles.some(role => role === projectOwner || role === projectManager)
      ) && this.getPermissionByModule('can-delete-file-design');
    },
    activeGroupFiles() {
      return this.getActiveStyle?.items?.filter(file => +file.gallery_group_id === +this.$route.query.gallery_group_id) || []
    },
    latestVersion() {
      return Math.max(...this.activeGroupFiles.map(file => file.version))
    }
  },
  methods: {
    getPermissionByModule(permission) {
      const project_id = this.$route.params.project_id;
      const activeModule = this.getProject.modules.find(module => module.id === +this.$route.query.module_id);
      return this.getPermission(project_id).design[permission] &&
        this.getProjectPermissionData(project_id).availableModules.includes(activeModule.slug);
    },
    deleteItems() {
      if (!this.getActiveFile || !this.deleteDisable) return;

      this.$store.dispatch('openModal', {
        modalName: 'confirmModal',
        data: {
          title: `Are you sure you want to delete this file?`,
          confirmBtnText: 'Delete',
        },
        handlers: {
          onConfirm: async () => {
            const items = this.getActiveStyle.items.map(file => file.id === this.getActiveFile.id ? {
              ...file,
              loading: true
            } : file);
            this.$store.dispatch('setActiveStyle', {...this.getActiveStyle, items});
            this.$store.dispatch('closeModal', 'confirmModal');


            try {
              await this.$api.projectModuleDesign.deleteFiles(this.$route.params.project_id, this.$route.query.module_id, {items: [this.getActiveFile.id]});

              const items = this.getActiveStyle.items.filter(file => file.id !== this.getActiveFile.id);
              const groupIdx = +Object.keys(this.getActiveStyle.grouped_items).indexOf(String(this.getActiveFile.gallery_group_id))

              this.$store.dispatch('setActiveStyle', {...this.getActiveStyle, items});
              if((this.getActiveStyle.grouped_items && Object.keys(this.getActiveStyle.grouped_items).length) && !this.getActiveStyle.grouped_items[this.$route.query.gallery_group_id]) {
                const currentGroupId = Object.keys(this.getActiveStyle.grouped_items)[groupIdx <= 0 ? 0 : groupIdx - 1]
                const currentFileId = this.getActiveStyle.grouped_items[currentGroupId][0].id
                const currentFile = items.find(file => +file.id === +currentFileId)
                if (this.getActiveFile.id === this.getActiveFile.id) {
                  this.$store.dispatch('setActiveFile', {...currentFile, loading: true});
                }
                this.$router.$updateQueryParams({gallery_group_id: currentGroupId, file_id: currentFileId});
              } else if (!this.getActiveStyle.grouped_items || !Object.keys(this.getActiveStyle.grouped_items).length) {
                this.$store.dispatch('setActiveFile', null);
                this.$router.$updateQueryParams({file_id: '', gallery_group_id: ''});
              } else  {
                const currentFileId = this.getActiveStyle.grouped_items[this.$route.query.gallery_group_id][0].id
                const currentFile = items.find(file => +file.id === +currentFileId)
                if (this.getActiveFile.id === this.getActiveFile.id) {
                  this.$store.dispatch('setActiveFile', {...currentFile, loading: true});
                }
                this.$router.$updateQueryParams({file_id: currentFile.id});
              }
            } catch (error) {
              console.error(error);
            } finally {
              const items = this.getActiveStyle.items.map(file => file.loading ? {...file, loading: true} : file);
              this.$store.dispatch('setActiveStyle', {...this.getActiveStyle, items});

              // we need to load the project to check if the module has files
              // if module don't have any files, it will be available to remove
              const project = await this.$api.project.get(this.getProject.id);
              this.$store.dispatch('setProject', project.data);
              this.$store.dispatch('setDesignModules', project.data.modules);
            }
          },
          onCancel: () => {
            this.$store.dispatch('closeModal', 'confirmModal');
          },
        },
      });
    },
    async downloadFile() {
      if (this.fileLoading || !this.getActiveFile) return;
      this.fileLoading = true;

      const image = await fetch(this.getActiveFile.file.download_url || this.getActiveFile.file.url);
      const imageBlog = await image.blob();
      const imageURL = URL.createObjectURL(imageBlog);

      const anchor = document.createElement('a');
      anchor.href = imageURL;
      anchor.download = this.getActiveFile.file.download_file_name || this.getActiveFile.file.original_name;

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      URL.revokeObjectURL(imageURL);
      this.fileLoading = false;
    },
    archiveFile() {
      if (!this.getPermissionByModule('can-archive-design')) return;

      this.$store.state.ProjectDesign.activeFile.loading = true;

      const items = this.getActiveStyle.items.map(file => file.id === this.getActiveFile.id ? {
        ...file,
        loading: true
      } : file);
      this.$store.dispatch('setActiveStyle', {...this.getActiveStyle, items});

      this.$api.projectModuleDesign.updateStatus(this.$route.params.project_id, this.$route.query.module_id, this.getActiveFile.id, 'archive')
        .then(res => {
          const items = this.getActiveStyle.items.map(file => file.id === res.data.id ? {
            ...res.data,
            loading: false
          } : file);
          this.$store.dispatch('setActiveStyle', {...this.getActiveStyle, items});
          this.$store.dispatch('setActiveFile', {...res.data, loading: false});
        })
        .catch(err => {
          console.error(err);

          this.$store.state.ProjectDesign.activeFile.loading = false;
          const items = this.getActiveStyle.items.map(file => file.loading ? {...file, loading: true} : file);
          this.$store.dispatch('setActiveStyle', {...this.getActiveStyle, items});
        })
    }
  },
}
</script>
